<template>
  <div class="container with-sidebar">
    <Sidebar />
    <div>
      <h1>Desertificación</h1>
      <p>
        Nuestras tierras se encuentran en Andalucía, en el Valle de los Pedroches. Esta zona forma parte del antiguo
        sistema agroforestal español llamado Dehesa. La dehesa es una especie de sabana de robles con diferentes tipos
        de robles que dominan el paisaje, mientras que los pastos y otras especies vegetales llenan los espacios entre
        ellos.
      </p>
      <p>
        Desgraciadamente, la dehesa está muriendo debido a muchos factores diferentes que incluyen
        el pastoreo excesivo e insuficiente. Como resultado, el suelo está compactado, no retiene bien el agua debido a
        la escasez de materia orgánica, y la diversidad de especies vegetales está disminuyendo y, dado que no llueve
        durante 6 meses en la época más calurosa del año, la situación es difícil.
      </p>
      <p>
        En invierno, las temperaturas mínimas rondan los -10° Celsius, y en verano podemos tener una temperatura
        ambiente de 45° Celsius a la sombra. En otoño, invierno y primavera llueve, y recibimos entre 500 y 600 mm de
        precipitaciones al año. Las personas mayores cuentan que antes hubo tormentas en verano, pero desde hace unas
        décadas
        ya no es así.
      </p>
      <p>
        El Gobierno español ha publicado información sobre el estado actual de la amenaza de desertificación en España.
        Consulte su página sobre
        <a href="https://www.miteco.gob.es/es/biodiversidad/temas/desertificacion-restauracion/lucha-contra-la-desertificacion/lch_espana.aspx"
          target="_blank">La desertificación en España</a> (Spanish)
      </p>

      <p>
        En octubre de 2017, con los animales del anterior propietario aún presentes, el terreno tenía este aspecto:
      </p>
      <picture>
        <source media="(max-width: 500px)" srcset="../../assets/images/sm-2017-10-01_18-33-44.jpg">
        <img src="../../assets/images/2017-10-01_18-33-44.jpg">
      </picture>

      <p>
        Tras unas lluvias en noviembre de 2017 surgió un poco de hierba corta que se consumió de inmediato:
      </p>
      <picture>
        <source media="(max-width: 500px)" srcset="../../assets/images/sm-2017-11-16_13-03-53.jpg">
        <img src="../../assets/images/2017-11-16_13-03-53.jpg">
      </picture>

      <p>
        Con la ayuda de un montón de animales manejados de forma diferente conseguimos entrar en la senda de la
        restauración como muestra esta imagen de abril de 2022:
      </p>
      <picture>
        <source media="(max-width: 500px)" srcset="../../assets/images/sm-2022-04-19_12-21-42.jpg">
        <img src="../../assets/images/2022-04-19_12-21-42.jpg">
      </picture>

      <p>
        A diferencia de antes, ahora (junio de 2022) podemos mantener el suelo cubierto de biomasa para mantener vivo el
        suelo en lugar de de dejar que el sol lo mate todo cada año:
      </p>
      <picture>
        <source media="(max-width: 500px)" srcset="../../assets/images/sm-2022-06-02_14-05-29.jpg">
        <img src="../../assets/images/2022-06-02_14-05-29.jpg">
      </picture>

      <YouTubeLink />

    </div>
  </div>
</template>

<script>
import Sidebar from './Sidebar.vue'
import YouTubeLink from '@/components/YouTubeLink.vue'

export default {
  name: "DesertificationES",
  components: {
    Sidebar,
    YouTubeLink
  },
};
</script>
